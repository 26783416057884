<template>
  <div style="padding: 0 10px">
    <div class="utils">
      <el-button type="primary" @click.stop.prevent="handleToAdd">新增分类</el-button>
      <div class="right" @click.stop.prevent>
        <el-input
          style="width: 250px;"
          suffix-icon="el-icon-search"
          placeholder="分类查询"
          v-model="pagination.keyword"
          @blur="handleBlur"
        ></el-input>
      </div>
    </div>
    <ul class="thead">
      <li class="name">分类名称</li>
      <li>分类编码</li>
      <li>关联标签分类</li>
      <li>关联货品</li>
      <li>更新人</li>
      <li>更新日期</li>
      <li>操作</li>
    </ul>
    <div @click.stop.prevent>
      <el-tree
        class="wms-goods-category-tree"
        :props="{
          children: 'children',
          label: 'name'
        }"
        accordion
        :data="categories"
        lazy
        ref="category"
        :filter-node-method="filterNode"
        :load="handleLoad"
        node-key="uuid"
      >
        <div class="tree-wrap" slot-scope="{ node, data }">
          <div class="arrow" v-show="data.hasChildren"></div>
          <ul class="custom-tree-node">
            <li class="name">
              <div v-show="!data.isEdit">{{data.name}}</div>
              <div v-show="data.isEdit">
                <el-input type="text" v-model="data.copyName" placeholder="请输入分类名称"></el-input>&nbsp;&nbsp;
              </div>
            </li>
            <li>
              <div  v-show="!data.isEdit">
                <span v-show="data.parentCode">{{data.parentCode}}</span>
                {{data.code}}
              </div>
              <div v-show="data.isEdit">
                <span v-show="data.parentCode">{{data.parentCode}}</span>
                <el-input
                  type="text"
                  style="width: 120px"
                  v-model="data.copyCode"
                  placeholder="请输入分类编码"
                ></el-input>&nbsp;&nbsp;
              </div>
            </li>
            <li class="center">
              <div v-show="!data.isEdit" style="text-align: center">{{data.labelSortName || '- -'}}</div>
              <div v-show="data.isEdit" style="text-align: center">
                <el-select v-model="data.labelSortUuid">
                  <el-option v-for="(item,index) in tagsSort" :key="index" :label="item.name" :value="item.uuid"></el-option>
                </el-select>
              </div>
            </li>
            <li class="center">{{data.goodsTotal}}</li>
            <li class="center">{{data.operatorName}}</li>
            <li class="center">{{data.updatedAt | formatDate}}</li>
            <li>
              <div v-show="!data.isEdit">
                <el-button type="text" @click.stop.prevent="handleToEdit(node,data)">编辑</el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button type="text" @click.stop.prevent="handleAddChild(node,data)">添加子分类</el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button type="text" class="danger" @click.stop.prevent="handleDel(node, data)">删除</el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button
                  type="text"
                  v-show="data.isTop===0"
                  @click.stop.prevent="handleToTop(node, data)"
                >置顶</el-button>
                <el-button
                  type="text"
                  v-show="data.isTop===1"
                  @click.stop.prevent="handleCancelTop(node, data)"
                >取消置顶</el-button>
              </div>
              <div v-show="data.isEdit">
                <el-button type="text" @click.stop.prevent="handleConfirm(data)">
                  <i class="el-icon-check"></i>
                </el-button>
                <el-button type="text" class="danger" @click.stop.prevent="handleCancelEdit(data,node)">
                  <i class="el-icon-close"></i>
                </el-button>
              </div>
            </li>
          </ul>
        </div>
      </el-tree>
      <div class="pagination">
        <el-pagination
          :page-sizes="[10, 15, 20, 30]"
          :page-size="pagination.limit"
          layout="sizes,prev, pager, next, ->,total"
          :total="pagination.total"
          @current-change="currentChange"
          @size-change="sizeChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="addVisible" title="添加分类" width="500px" :before-close="handleBeforeClose">
      <el-form>
        <el-form-item label="分类名称">
          <el-input v-model="param.name"></el-input>
        </el-form-item>
        <el-form-item label="分类编码">
          <el-input v-model="param.code"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="handleBeforeClose">取消</el-button>
        <el-button type="primary" @click="handleConfirmAddChild">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { GoodsSortUnion, AddGoodsSort, DelGoodsSort, GoodsSort, UpdateGoodsSort, ToTop, CancelTop, GoodsTagsSort } from '@/api/wms/set'
import { Tree, Divider } from 'element-ui'
export default {
  data () {
    return {
      categories: [],
      currentData: null,
      currentNode: null,
      categoryName: '',
      param: {
        name: '',
        code: '',
        parentUuid: '',
        labelSortUuid: '',
        isTop: 0
      },
      pagination: {
        start: 0,
        limit: 10,
        keyword: '',
        parentUuid: ''
      },
      addVisible: false,
      tagsSort: []
    }
  },
  methods: {
    init () {
      GoodsSortUnion(this.pagination).then(res => {
        if (res.code === 0) {
          let data = res.data.list || []
          this.categories = data.map(v => ({ ...v, copyName: v.name, copyCode: v.code, isEdit: false }))
          this.pagination.total = Number(res.data.total)
        }
      })
    },
    initTagsSort () {
      GoodsTagsSort().then(res => {
        this.tagsSort = res.data || []
      })
    },
    handleBlur () {
      GoodsSortUnion(this.pagination).then(res => {
        if (res.code === 0) {
          let data = res.data.list || []
          this.categories = data.map(v => ({ ...v, copyName: v.name, copyCode: v.code, isEdit: false }))
          this.pagination.total = Number(res.data.total)
        }
      })
    },
    handleToEdit (node, data) {
      data.isEdit = true
      this.isEdit = true
    },
    handleCancelEdit (item, node) {
      if (!item.uuid && !this.isEdit) {
        this.$refs.category.remove(node)
      } else {
        item.copyName = item.name
        item.isEdit = false
        item.copyCode = item.code
      }
    },
    handleConfirm (data) {
      if (this.isEdit) {
        let { uuid, copyName, copyCode, labelSortUuid } = data
        UpdateGoodsSort({ uuid, name: copyName, code: copyCode, labelSortUuid }).then(res => {
          if (res.code === 0) {
            data.name = data.copyName
            data.code = data.copyCode
            data.labelSortUuid = labelSortUuid
            if (labelSortUuid) {
              data.labelSortName = this.tagsSort.filter(v => v.uuid === labelSortUuid)[0].name
            }
            data.isEdit = false
          }
        })
      } else {
        let { copyName, copyCode, labelSortUuid } = data
        AddGoodsSort({ name: copyName, code: copyCode, labelSortUuid }).then(res => {
          if (res.code === 0) {
            this.$message.success('新增成功')
            data.uuid = res.data
            data.name = copyName
            data.code = copyCode
            data.hasChildren = false
            data.isEdit = false
            data.labelSortUuid = labelSortUuid
            data.isTop = 0
            if (labelSortUuid) {
              data.labelSortName = this.tagsSort.filter(v => v.uuid === labelSortUuid)[0].name
            }
          }
        })
      }
    },
    handleAddChild (node, data) {
      this.currentNode = node
      this.currentData = data
      this.param.parentUuid = data.uuid
      this.addVisible = true
    },
    handleConfirmAddChild () {
      AddGoodsSort(this.param).then(res => {
        if (res.code === 0) {
          this.$message.success('新增成功')
          this.currentData.hasChildren = 1
          this.pagination.parentUuid = this.param.parentUuid

          GoodsSort(this.pagination).then(res => {
            if (res.code === 0) {
              let data = res.data || []
              let parentCode = this.currentNode.data.parentCode + this.currentNode.data.code + '-'
              data = data.map(v => ({ ...v, parentCode, isEdit: false, copyName: v.name, copyCode: v.code, children: [] }))
              this.$refs.category.updateKeyChildren(this.currentData.uuid, data)
            }
          })
          this.handleBeforeClose()
        }
      })
    },
    handleToAdd () {
      this.isEdit = false
      if (this.currentData) {
        this.$refs.category.append({
          uuid: '',
          parentUuid: this.currentData.uuid,
          name: '',
          copyName: '',
          copySort: '',
          sort: '',
          isEdit: true,
          createdAt: parseInt(new Date().getTime() / 1000),
          children: []
        }, this.currentNode)
      } else {
        this.categories.push({
          uuid: '',
          parentUuid: '',
          name: '',
          copyName: '',
          sort: '',
          copySort: '',
          isEdit: true,
          createdAt: parseInt(new Date().getTime() / 1000),
          children: []
        })
      }
    },
    handleDel (node, data) {
      let { uuid } = data
      this.$confirm('删除后数据不可恢复,是否确认删除？', '温馨提示', { type: 'warning' }).then(() => {
        DelGoodsSort({ uuid }).then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功')
            this.$refs.category.remove(node)
          }
        })
      }).catch(() => {

      })
    },
    filterNode (value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    handleLoad (node, resolve) {
      this.pagination.parentUuid = node.data.uuid
      if (node.data.hasChildren) {
        GoodsSort(this.pagination).then(res => {
          if (res.code === 0) {
            let data = res.data || []
            let pcode = ''
            if (Array.isArray(node.data)) {
              pcode = ''
            } else {
              let { parentCode, code } = node.data
              pcode = parentCode ? parentCode + code + ' - ' : code + ' - '
            }

            data = data.map(v => ({ ...v, parentCode: pcode, isEdit: false, copyName: v.name, copyCode: v.code, children: [] }))
            resolve(data)
          }
        })
      } else {
        resolve([])
      }
    },
    handleToTop (node, data) {
      let { uuid } = data
      ToTop({ uuid }).then(res => {
        if (res.code === 0) {
          data.isTop = 1
        }
      })
    },
    handleCancelTop (node, data) {
      let { uuid } = data
      CancelTop({ uuid }).then(res => {
        if (res.code === 0) {
          data.isTop = 0
        }
      })
    },
    currentChange (current) {
      this.pagination.start = (current - 1) * this.pagination.limit
      this.init()
    },
    sizeChange (size) {
      this.pagination.limit = size
      this.init()
    },
    handleBeforeClose () {
      this.param = {
        name: '',
        uuid: '',
        parentUuid: ''
      }
      this.addVisible = false
    }
  },
  mounted () {
    this.initTagsSort()
    this.init()
  },
  components: {
    ElTree: Tree,
    ElDivider: Divider
  },

  watch: {
    categoryName (val) {
      this.$refs.category.filter(val)
    }
  }
}
</script>

<style lang="scss">
.el-tree {
  &.wms-goods-category-tree {
    &>.el-tree-node{
      &:hover{
        box-shadow: $-box-shadow;
      }
    }
    .el-tree-node__expand-icon {
      font-size: 16px;
      position: relative;
      z-index: 1;
      color: transparent;
    }

    .el-tree-node__content {
      height: 45px;
      line-height: 45px;
    }

    .custom-tree-node {
      width: 100%;
      display: flex;

      li {
        //padding: 0 $-size-unit;
        &.center {
          text-align: center;
        }
        &.name {
          flex: 1;
        }
        &:nth-child(2) {
          width: 200px;
        }
        &:nth-child(3) {
          width: 150px;
        }
        &:nth-child(4) {
          width: 100px;
        }
        &:nth-child(5) {
          width: 100px;
        }
        &:nth-child(6) {
          width: 100px;
        }
        &:nth-child(7) {
          width: 290px;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.tree-wrap {
  width: 100%;
  position: relative;
  .arrow {
    border: 6px solid transparent;
    border-left-color: #666;
    position: absolute;
    left: -16px;
    top: 17px;
  }
}
.thead {
  // border-bottom: 1px solid $-border-color;
  display: flex;
  li {
    float: left;
    height: 35px;
    line-height: 35px;
    color: #999;
    background-color: #f8f8f8;
    text-align: center;
    &.name {
      flex: 1;
    }
    &:nth-child(2) {
      width: 200px;
    }
    &:nth-child(3) {
      width: 150px;
    }
    &:nth-child(4) {
      width: 100px;
    }
    &:nth-child(5) {
      width: 100px;
    }
    &:nth-child(6) {
      width: 100px;
    }
    &:nth-child(7) {
      width: 290px;
    }
  }
}
</style>
